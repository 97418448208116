
.wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 60px;
  margin-right: 60px;
  @media (max-width: 1000px) {
    margin-left: 15px;
    margin-right: 15px;
  }
}

.logo {
  width: 225px;
}
.header {
  height: 105px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 60px;
  overflow: hidden;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  ul {
    display: flex;
    list-style: none;
  }

  .nav-items {
    margin: 0 24px;
    font-weight: 500;
  }
  .btn-dark,
  .btn-light {
    margin-left: 24px;
  }
}

.btn-light {
  background: #ffffff;
  border: 2px solid #df0050;
  box-sizing: border-box;
  padding: 12px 25px;
  border-radius: 4px;
  color: #df0050;
  font-weight: 600;
  cursor: pointer;
}

.btn-dark {
  background: #df0050;
  border: 2px solid #df0050;
  box-sizing: border-box;
  padding: 12px 25px;
  border-radius: 4px;
  color: #ffffff;
  font-weight: 600;
  cursor: pointer;
}

.slider-home {
  display: flex;
  align-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 600px;
  width: 100%;
  background-image: url(../img/slider.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.slider-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 55px;
  line-height: 66px;
  color: #ffffff;
  margin-left: 60px;
  max-width: 900px;
}

.castings-grid {

  margin-top: 60px;

 
}
.user-cards {
  display: flex;
  flex-wrap: wrap;
  
}
.section-title {
  margin-top: 60px;
  color: #df0050;
  font-weight: 600;
  font-size: 24px;
  text-transform: uppercase;

}
.user-card {
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 300px;
  background: #fcfcfc;
  -webkit-box-shadow: 0px 8px 23px -3px #e2e2e2; 
  box-shadow: 0px 8px 23px -3px #e2e2e2;
  padding: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  text-align: center;
 
}
.user-img-card {
    margin: 80px 0;
}

.user-avatar {

  img {
    width: 180px;
    height: 180px;
    object-fit: cover;
    clip-path: circle();
    object-position: center;
    //image-rendering: optimizeSpeed;             /*                     */
    //image-rendering: -moz-crisp-edges;          /* Firefox             */
    //image-rendering: -o-crisp-edges;            /* Opera               */
    //image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
    //image-rendering: pixelated;                 /* Chrome as of 2019   */
    //image-rendering: optimize-contrast;         /* CSS3 Proposed       */
   // -ms-interpolation-mode: nearest-neighbor;   /* IE8+                */
    
  }
}

.footer {
  height: 300px;
  background: rgb(26, 26, 26);
  margin-top: 50px;
}

.btn-text {
  background: transparent;
  border: none;
  box-sizing: border-box;
  padding: 10px 25px;
  border-radius: 4px;
  color: #df0050;
  font-weight: 600;
  cursor: pointer;
}

.user-profile-card {
  max-width: 900px;
  @media(max-width: 1000px) {
    .profile-avatar img {
      max-width: 300px;
    }
  }
}

.btns {
  display: flex;
  align-content: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
  

.card-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 1900px;
  align-content: center;
  justify-content: center;
  gap: 40px;

}

.castings {
  display: flex;
  flex-wrap: wrap;
  max-width: 1900px;
  align-content: center;
  justify-content: center;
 

}

.casting {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-box-shadow: 0px 8px 23px -3px #e2e2e2; 
  box-shadow: 0px 8px 23px -3px #e2e2e2;
  padding: 24px;
  width: 550px;
  height: 300px;
  border-radius: 8px;
  // &:last-child {
  //   margin-right: auto;
  // }
  //font-size: 14px;
  
}

.casting-content {
 overflow: hidden;
 text-overflow: ellipsis;
 
}

.casting-header{
  display: flex;
  justify-content: space-between;
}

.casting-top-header {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #727272;
}

.casting-bottom-header {
  display: flex;
  justify-content: space-between;

}

.casting-bottom {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  justify-self: flex-end;
}

.castings {
  margin-bottom: 100px;
  gap: 40px;
}

 .casting-detail {
   max-width: 600px;
   margin: 60px 0 180px 60px;
  
   .button  {
     max-width: 200px;
     margin-top: 40px;
     text-align: center;
     cursor: pointer;
   }
 }

 .login{
   margin-top: 30px;
   margin-left: 45px;
  //  min-height: 60vh;
 }

 .login-input {
   padding: 12px;
   margin: 10px;
 }

 .input-btn {
   margin: 10px;
 }



 .header-name {
   font-size: 24px;
   font-weight: 600;
   padding: 0 0 0 20px;
 }

 .loaders {
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
}

.loader {
  min-width: 100px;
}

.deleted-message {
  background: #c1ffd3;
  padding: 40px;
  p {
    font-weight: 500;
  }
}

/* circle loaders */

.circle-loader-1 {
  width: 100px;
  min-width: 100px;
  height: 100px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 50%;
  border-top: 10px solid rgba(68, 68, 68, 0.2);
  border-right: 10px solid rgba(68, 68, 68, 0.2);
  border-bottom: 10px solid rgba(68, 68, 68, 0.2);
  border-left: 10px solid rgb(26, 25, 25);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: circle-loader-spin 1s infinite linear;
  animation: circle-loader-spin 1s infinite linear;
}


@keyframes circle-loader-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-text {
  text-align: center;
  font-size: 20px;
}

h1 {
  margin: 50px 0;
}

.img-uploaded {
  max-width: 500px;
}

//table